"use client";
import React, { useEffect } from "react";
import { useTranslations } from "next-intl";
import { Divider, Skeleton, Typography } from "antd/lib";
import MyCard from "@/app/components/MyCard";
import useAxiosQuery from "@/hooks/useMyQuery";
import type { INewsResponse, NewsResponseCDN } from "@/models/news";
import { IMAGE_SHARE_CDN } from "@/constant/apiPath";
import { NewsFromProjectCategory, sanitizeHtmlContent } from "@/helpers";
import imageBlog from "@/asset/images/image-blog.png";
import imageExample from "@/asset/images/image-example.png";
import { motion } from "framer-motion";
import NewsInfo from "@/app/components/NewsInfo";
import Link from "next/link";
import LinkToKlardaApp from "./LinkToKlardaApp";
import { useLanguageContext } from "@/context/LanguageContext";

export const NewCard = React.memo(
  ({
    article,
    index,
    isShowImageFull,
    onClickNews,
  }: {
    article: INewsResponse;
    index: number;
    isShowImageFull?: boolean;
    onClickNews?: (news: INewsResponse) => void;
  }) => {
    const { language } = useLanguageContext();
    const title = sanitizeHtmlContent(article.title || article.description || "");
    const description = sanitizeHtmlContent(article.description || article.content || "");

    const imageUrl = article?.is_klarda
      ? `${IMAGE_SHARE_CDN}${article?.thumbnail}`
      : article.thumbnail || (article.klarda_categories?.includes(NewsFromProjectCategory) ? imageBlog : imageExample);

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="flex items-center space-x-4"
        onClick={() => onClickNews && onClickNews(article)}
      >
        {isShowImageFull || index === 0 ? (
          <Link
            href={article?.is_klarda ? `/${language}/news/blogs/${article?.link}` : `/${language}/news/${article?.id}`}
            passHref
          >
            {isShowImageFull ? (
              <img
                // crossOrigin="anonymous"
                onError={e => {
                  e.currentTarget.src = "https://d37pr5wbjsk01d.cloudfront.net/1725547867815.png";
                }}
                src={imageUrl as string}
                alt={title}
                width={100}
                className="rounded h-[90px] min-w-[110px]"
              />
            ) : (
              index === 0 && (
                <img
                  onError={e => {
                    e.currentTarget.src = "https://d37pr5wbjsk01d.cloudfront.net/1725547867815.png";
                  }}
                  src={imageUrl as string}
                  alt={title}
                  width={100}
                  className="rounded h-[80px]"
                />
              )
            )}
          </Link>
        ) : null}

        <div className="flex flex-col w-full gap-2">
          <div className="space-y-1">
            <Typography.Link
              title={title}
              href={
                article?.is_klarda ? `/${language}/news/blogs/${article?.link}` : `/${language}/news/${article?.id}`
              }
            >
              <Typography.Title level={5} className="line-clamp-2 m-0 text-sm">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </Typography.Title>
            </Typography.Link>
            {index === 0 && (
              <Typography.Text title={description} type="secondary" className="line-clamp-1">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Typography.Text>
            )}
          </div>
          <NewsInfo news={article} />
        </div>
      </motion.div>
    );
  },
);

NewCard.displayName = "ArticleCard";

interface CommonNewsProps {
  title?: string;
  extra?: React.ReactNode;
  limit?: number;
  apiUrl: string;
  category?: string;
  isShowImageFull?: boolean;
  onClickNews?: (news: INewsResponse) => void;
}

const MarketInsightCard: React.FC<CommonNewsProps> = ({
  title,
  limit = 6,
  extra,
  apiUrl,
  isShowImageFull,
  onClickNews,
  category = "",
}) => {
  const t = useTranslations("home");
  const { data: newsData, isLoading } = useAxiosQuery<NewsResponseCDN>({
    url: apiUrl,
    method: "get",
  });
  const { language } = useLanguageContext();

  useEffect(() => {
    if (onClickNews && newsData?.news?.length) {
      onClickNews(newsData.news[0]);
    }
  }, [newsData?.news]);

  return (
    <MyCard title={title ? t(title) : ""} extra={extra} styles={{ body: { padding: 0 } }}>
      <>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          <>
            <div className="space-y-[5px]">
              {newsData?.news?.slice(1, limit)?.map((item, index) => (
                <div key={index} className="mycard-hover">
                  <MyCard styles={{ body: { padding: 0 } }}>
                    <NewCard article={item} index={index} isShowImageFull={isShowImageFull} />
                  </MyCard>
                  {index < newsData.news.slice(1, limit).length - 1 && <Divider className="my-2" />}
                </div>
              ))}
            </div>
            <LinkToKlardaApp isOnlyText url={`/${language}/news/search?category=${category}`} className="mt-6 block" />
          </>
        )}
      </>
    </MyCard>
  );
};

export default MarketInsightCard;
