"use client";
import Slider from "react-slick";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Card, Skeleton, Typography } from "antd/lib";
import useAxiosQuery from "@/hooks/useMyQuery";
import NewsInfo from "@/app/components/NewsInfo";
import { CLOUDFRONT_URL, IMAGE_SHARE_CDN } from "@/constant/apiPath";
import type { INewsResponse, NewsResponseCDN } from "@/models/news";
import { NewsFromProjectCategory, processNewsData } from "@/helpers";
import MyCard from "@/app/components/MyCard";
import { useTranslations } from "next-intl";
import { createSliderSettings } from "@/app/components/slider/SliderSettings";
import LinkToKlardaApp from "./LinkToKlardaApp";
import { useLanguageContext } from "@/context/LanguageContext";
import imageBlog from "@/asset/images/image-blog.png";
import imageExample from "@/asset/images/image-example.png";

const TopNews = React.memo(({ title, subTitle }: { title: string; subTitle: string }) => {
  const mainSliderRef = useRef<Slider | null>(null);
  const secondarySliderRef = useRef<Slider | null>(null);
  const [activeMainSlide, setActiveMainSlide] = useState(0);
  const [activeSecondarySlide, setActiveSecondarySlide] = useState(0);
  const t = useTranslations("home");
  const { language } = useLanguageContext();

  const { data: newsData, isLoading: isLoadingMain } = useAxiosQuery<NewsResponseCDN>({
    url: `${CLOUDFRONT_URL}/v1/market-insight/news/is-klarda`,
    method: "get",
  });

  const { data: bottomNewsData, isLoading: isLoadingSecondary } = useAxiosQuery<NewsResponseCDN>({
    url: `${CLOUDFRONT_URL}/v1/market-insight/news`,
    method: "get",
  });

  const mainSliderSettings = useMemo(
    () => createSliderSettings(mainSliderRef, activeMainSlide, setActiveMainSlide, 1, true),
    [activeMainSlide],
  );

  const secondarySliderSettings = useMemo(
    () => createSliderSettings(secondarySliderRef, activeSecondarySlide, setActiveSecondarySlide, 3, true),
    [activeSecondarySlide],
  );

  const renderMainNewsCard = useCallback(
    (news: INewsResponse) => {
      const { description, title } = processNewsData(news);

      return (
        <div key={news.id} className="flex flex-col lg:flex-row">
          <div className="w-full lg:pr-4 mb-2 lg:mb-3">
            <div className="space-y-1">
              <Typography.Link
                title={title}
                href={news.is_klarda ? `/${language}/news/${news.link}` : `/${language}/news/${news.id}`}
              >
                <Typography.Title level={5} className="line-clamp-3">
                  <div dangerouslySetInnerHTML={{ __html: title }} />
                </Typography.Title>
              </Typography.Link>
              <Typography.Link
                href={news.is_klarda ? `/${language}/news/${news.link}` : `/${language}/news/${news.id}`}
              >
                <Typography.Text title={description} type="secondary" className="line-clamp-5">
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Typography.Text>
              </Typography.Link>
            </div>
            <div className="flex items-center mt-4">
              <NewsInfo news={news} />
            </div>
          </div>
        </div>
      );
    },
    [language],
  );

  const renderSecondaryNewsCard = useCallback(
    (news: INewsResponse, index: number) => {
      const { description, title, imageUrl } = processNewsData(news);

      return (
        <div key={index} className="py-2 px-1">
          <Card
            styles={{
              body: {
                padding: 8,
              },
              cover: { padding: 8 },
            }}
            hoverable
            cover={
              <img
                onError={e => {
                  e.currentTarget.src = "https://d37pr5wbjsk01d.cloudfront.net/1725547867815.png";
                }}
                className="rounded-lg h-[250px]"
                src={imageUrl as string}
                alt={news.title || "News Image"}
              />
            }
          >
            <Typography.Link
              title={title}
              href={news.is_klarda ? `/${language}/news/${news.link}` : `/${language}/news/${news.id}`}
            >
              <Typography.Title level={5} className="line-clamp-2">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </Typography.Title>
            </Typography.Link>
            <Typography.Text title={description} type="secondary" className="line-clamp-2">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Typography.Text>
            <div className="flex items-center mt-4">
              <NewsInfo news={news} />
            </div>
          </Card>
        </div>
      );
    },
    [language],
  );

  const slidesData = useMemo(
    () => bottomNewsData?.news?.map(renderSecondaryNewsCard),
    [bottomNewsData, renderSecondaryNewsCard],
  );

  const sanitizedNews = useMemo(() => newsData?.news?.map(renderMainNewsCard), [newsData, renderMainNewsCard]);
  const imageUrl = newsData?.news[activeMainSlide].is_klarda
    ? `${IMAGE_SHARE_CDN}${newsData?.news[activeMainSlide].thumbnail}`
    : newsData?.news[activeMainSlide].thumbnail ||
      (newsData?.news[activeMainSlide].klarda_categories?.includes(NewsFromProjectCategory) ? imageBlog : imageExample);

  return (
    <MyCard
      title={title}
      extra={<LinkToKlardaApp isOnlyText label={"More news >"} url={"en/news"} />}
      className="lg:col-span-8"
      styles={{ header: { border: "none" }, body: { padding: 0 } }}
    >
      {isLoadingMain ? (
        <Skeleton active paragraph={{ rows: 8 }} />
      ) : (
        <MyCard title={subTitle} size="small">
          <div className="flex flex-col md:flex-row gap-2 md:gap-6">
            <div className="md:w-5/12 order-2 md:order-1">
              <Slider {...mainSliderSettings} ref={mainSliderRef} className="p-2 h-full justify-between flex flex-col">
                {sanitizedNews}
              </Slider>
            </div>
            <div className="md:w-7/12 order-1 md:order-2">
              <a
                href={
                  newsData?.news[activeMainSlide].is_klarda
                    ? `/${language}/news/blogs/${newsData?.news[activeMainSlide].link}`
                    : `/${language}/news/${newsData?.news[activeMainSlide]?.id}`
                }
                className="h-[200px] md:h-[270px] flex items-center justify-center"
              >
                <img
                  src={typeof imageUrl === "string" ? imageUrl : imageUrl.src}
                  alt={newsData?.news[activeMainSlide]?.title || "News Image"}
                  className="max-h-full max-w-full w-auto rounded-lg object-contain"
                  style={{
                    maxHeight: "100%",
                    width: "auto",
                    margin: "0 auto",
                  }}
                />
              </a>
            </div>
          </div>
        </MyCard>
      )}
      {isLoadingSecondary ? (
        <Skeleton active paragraph={{ rows: 3 }} className="mt-2" />
      ) : (
        <MyCard title={t("Latest_news")} size="small" className="mx-1 mt-2">
          <Slider {...secondarySliderSettings} ref={secondarySliderRef}>
            {slidesData}
          </Slider>
        </MyCard>
      )}
    </MyCard>
  );
});
TopNews.displayName = "TopNews";

export default TopNews;
