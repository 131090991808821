"use client";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Empty, Select, Skeleton } from "antd/lib";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export interface MyColumnType<T> {
  title: string;
  dataIndex: string;
  key: string;
  render?: (value: any, record: T, index: number) => React.ReactNode;
  sorter?: ((a: T, b: T) => number) | boolean;
  className?: string;
  align?: "left" | "center" | "right";
  width?: number | string;
}

interface PaginationProps {
  pageSizeOptions?: number[];
  defaultPageSize?: number;
  totalRecords?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (size: number) => void;
  showPageSizeOptions?: boolean;
}

interface ScrollProps {
  y?: number;
  x?: number | string;
}

interface TableProps<T> {
  columns: MyColumnType<T>[];
  dataSource: T[];
  className?: string;
  paginationMode?: "default" | "infinite";
  pagination?: PaginationProps | boolean;
  scroll?: ScrollProps;
  isLoading?: boolean;
  hasMore?: boolean;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  rowClassName?: (record: T) => string;
  onRowClick?: (record: T) => void;
  onRow?: (record: T, index: number) => React.HTMLAttributes<HTMLTableRowElement>;
  showBorder?: boolean;
  striped?: boolean;
  allData?: T[]; // Optional full dataset for global sorting
  onSort?: (field: keyof T, order: "ascend" | "descend" | null) => void; // Optional callback for external sorting
}

const isPaginationProps = (pagination: PaginationProps | boolean): pagination is PaginationProps =>
  typeof pagination === "object";

const CustomTable = <T extends Record<string, any>>({
  columns,
  dataSource,
  className = "",
  paginationMode = "default",
  pagination = false,
  scroll,
  isLoading = false,
  hasMore = false,
  onLoadMore,
  isLoadingMore = false,
  rowClassName,
  onRowClick,
  onRow,
  showBorder = true,
  striped = true,
  allData,
  onSort,
}: TableProps<T>) => {
  const [sortField, setSortField] = useState<keyof T | null>(null);
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    isPaginationProps(pagination) && pagination.defaultPageSize ? pagination.defaultPageSize : 10,
  );

  const tableHeaderRef = useRef<HTMLDivElement>(null);
  const tableBodyRef = useRef<HTMLDivElement>(null);

  const pageSizeOptions = useMemo(
    () => (isPaginationProps(pagination) && pagination.pageSizeOptions ? pagination.pageSizeOptions : [5, 10, 20]),
    [pagination],
  );

  const totalRecords = useMemo(
    () => (isPaginationProps(pagination) && pagination.totalRecords ? pagination.totalRecords : dataSource.length),
    [pagination, dataSource.length],
  );

  const getColumnStyle = useCallback((column: MyColumnType<T>) => {
    const style: React.CSSProperties = {};
    if (column.width) {
      style.width = typeof column.width === "number" ? `${column.width}px` : column.width;
      style.maxWidth = style.width;
    }
    return style;
  }, []);

  const handleSort = useCallback(
    (field: keyof T, customSorter?: (a: T, b: T) => number) => {
      setSortField(prevField => {
        const newOrder =
          prevField === field
            ? sortOrder === "ascend"
              ? "descend"
              : sortOrder === "descend"
              ? null
              : "ascend"
            : "ascend";

        setSortOrder(newOrder);

        // If external sorting is provided, use it
        if (onSort) {
          onSort(field, newOrder);
          return field;
        }

        return field;
      });
    },
    [sortOrder, onSort],
  );

  const sortedData = useMemo(() => {
    if (!sortField || !sortOrder) return dataSource;

    // If external sorting is being used, just return the dataSource
    if (onSort) return dataSource;

    const dataToSort = allData || dataSource;
    const sorted = [...dataToSort].sort((a, b) => {
      const column = columns.find(col => col.dataIndex === sortField);
      if (column && typeof column.sorter === "function") {
        return sortOrder === "ascend" ? column.sorter(a, b) : column.sorter(b, a);
      }

      const aValue = a[sortField];
      const bValue = b[sortField];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "ascend" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      if (aValue < bValue) return sortOrder === "ascend" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "ascend" ? 1 : -1;
      return 0;
    });

    // If we're sorting allData, we need to return only the visible portion
    return allData ? sorted.slice(0, dataSource.length) : sorted;
  }, [dataSource, allData, sortField, sortOrder, columns, onSort]);

  const totalPages = useMemo(() => Math.ceil(totalRecords / pageSize), [totalRecords, pageSize]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
        if (pagination && isPaginationProps(pagination) && pagination.onPageChange) {
          pagination.onPageChange(newPage);
        }
      }
    },
    [pagination, totalPages],
  );

  const handlePageSizeChange = useCallback(
    (newSize: number) => {
      setPageSize(newSize);
      setCurrentPage(1);
      if (pagination && isPaginationProps(pagination) && pagination.onPageSizeChange) {
        pagination.onPageSizeChange(newSize);
      }
    },
    [pagination],
  );

  const handleScroll = useCallback(() => {
    if (paginationMode === "infinite" && onLoadMore && hasMore && !isLoadingMore) {
      if (tableBodyRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
        if (scrollHeight - scrollTop - clientHeight < 20) {
          onLoadMore();
        }
      }
    }
  }, [paginationMode, onLoadMore, hasMore, isLoadingMore]);

  const syncScroll = useCallback(() => {
    if (tableHeaderRef.current && tableBodyRef.current) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  }, []);

  useEffect(() => {
    const bodyRef = tableBodyRef.current;
    if (bodyRef) {
      bodyRef.addEventListener("scroll", syncScroll);
      if (paginationMode === "infinite") {
        bodyRef.addEventListener("scroll", handleScroll);
      }

      return () => {
        bodyRef.removeEventListener("scroll", syncScroll);
        if (paginationMode === "infinite") {
          bodyRef.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [syncScroll, handleScroll, paginationMode]);

  const renderHeaderCell = useCallback(
    (column: MyColumnType<T>, index: number) => (
      <th
        key={column.key + index}
        className={twMerge(
          "px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider",
          showBorder && "border-b border-gray-200",
          column.className,
          classNames({
            "text-left": column.align === "left" || !column.align,
            "text-center": column.align === "center",
            "text-right": column.align === "right",
          }),
        )}
        style={getColumnStyle(column)}
        onClick={() => column.sorter && handleSort(column.dataIndex as keyof T)}
      >
        <div
          className={classNames("flex items-center space-x-2", {
            "justify-end": column.align === "right",
            "justify-center": column.align === "center",
            "justify-start": column.align === "left" || !column.align,
          })}
        >
          <span>{column.title}</span>
          {column.sorter && (
            <span className="flex flex-col">
              <CaretUpOutlined
                className={sortField === column.dataIndex && sortOrder === "ascend" ? "text-blue-500" : "text-gray-400"}
              />
              <CaretDownOutlined
                className={
                  sortField === column.dataIndex && sortOrder === "descend" ? "text-blue-500" : "text-gray-400"
                }
              />
            </span>
          )}
        </div>
      </th>
    ),
    [sortField, sortOrder, handleSort, getColumnStyle, showBorder],
  );

  const renderBodyRow = useCallback(
    (record: T, index: number) => {
      const rowEventHandlers = onRow?.(record, index) || {};

      return (
        <tr
          key={index}
          onClick={e => {
            rowEventHandlers.onClick?.(e as React.MouseEvent<HTMLTableRowElement>);
            onRowClick?.(record);
          }}
          onMouseEnter={rowEventHandlers.onMouseEnter}
          onMouseLeave={rowEventHandlers.onMouseLeave}
          className={classNames(rowClassName?.(record), {
            "bg-gray-50": striped && index % 2 === 1,
            "hover:bg-gray-100": onRowClick,
            "border-b border-gray-200": showBorder,
          })}
        >
          {columns.map(column => (
            <td
              key={column.key}
              className={twMerge(
                "px-6 py-4",
                classNames({
                  "text-left": column.align === "left" || !column.align,
                  "text-center": column.align === "center",
                  "text-right": column.align === "right",
                  "cursor-pointer": onRowClick,
                }),
                column.className,
              )}
              style={getColumnStyle(column)}
            >
              {column.render ? column.render(record[column.dataIndex], record, index) : record[column.dataIndex]}
            </td>
          ))}
        </tr>
      );
    },
    [columns, onRowClick, rowClassName, getColumnStyle, onRow, showBorder, striped],
  );

  const renderLoadingState = useCallback(
    () => (
      <tr>
        <td colSpan={columns.length} className="p-4">
          <Skeleton active paragraph={{ rows: 5 }} />
        </td>
      </tr>
    ),
    [columns.length],
  );

  const renderEmptyState = useCallback(
    () => (
      <tr>
        <td colSpan={columns.length} className="p-4">
          <Empty />
        </td>
      </tr>
    ),
    [columns.length],
  );

  const tableContainerStyles = useMemo<React.CSSProperties>(
    () => ({
      position: "relative",
    }),
    [],
  );

  const tableBodyStyles = useMemo<React.CSSProperties>(
    () => ({
      overflowX: "auto",
      overflowY: scroll?.y ? "auto" : "visible",
      maxHeight: scroll?.y ? `${scroll.y}px` : undefined,
    }),
    [scroll],
  );

  const tableStyles = useMemo<React.CSSProperties>(
    () => ({
      width: scroll?.x ? (typeof scroll.x === "number" ? `${scroll.x}px` : scroll.x) : "100%",
      tableLayout: "fixed",
    }),
    [scroll],
  );

  const tableClasses = classNames("w-full", {
    "divide-y divide-gray-200": showBorder,
  });

  return (
    <div className={`${className}`} style={tableContainerStyles}>
      <div ref={tableHeaderRef} className="overflow-x-hidden">
        <table className={tableClasses} style={tableStyles}>
          <thead className={classNames("bg-gray-50", { "border-b border-gray-200": showBorder })}>
            <tr>{columns.map((column, index) => renderHeaderCell(column, index))}</tr>
          </thead>
        </table>
      </div>

      <div ref={tableBodyRef} style={tableBodyStyles}>
        <table className={tableClasses} style={tableStyles}>
          <tbody>
            {isLoading
              ? renderLoadingState()
              : sortedData.length > 0
              ? sortedData.map((record, index) => renderBodyRow(record, index))
              : renderEmptyState()}
          </tbody>
        </table>

        {paginationMode === "infinite" && isLoadingMore && (
          <div className="p-4">
            <Skeleton active />
          </div>
        )}
      </div>

      {paginationMode === "default" && pagination && isPaginationProps(pagination) && (
        <div className="flex flex-col sm:flex-row items-center justify-between py-3 space-y-3 sm:space-y-0 sm:space-x-4">
          {pagination.showPageSizeOptions && (
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">Rows per page:</span>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                className="ml-2"
                options={pageSizeOptions.map(size => ({
                  label: size.toString(),
                  value: size,
                }))}
                style={{ width: 80 }}
              />
            </div>
          )}
          <div className="flex items-center space-x-4">
            <Button
              type="default"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="text-sm"
              icon={<LeftOutlined />}
            />
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              type="default"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="text-sm"
              icon={<RightOutlined />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTable;