"use client";
import { Avatar, Typography } from "antd/lib";
import { ellipsisString } from "@/helpers";
import dayjs from "@/utils/dayjsConfig";
import { useMemo } from "react";
import logoBlog from "@/asset/images/logo-blog-klarda.png";
import ethLogo from "@/asset/images/chains/eth.svg";
import { DateTimeFormat } from "@/constant";
import type { INewsResponse } from "@/models/news";
import Image from "next/image";
import { useTimezoneContext } from "@/context/TimezoneContext";

interface NewsInfoProps {
  news: INewsResponse;
}

export default function NewsInfo({ news }: NewsInfoProps) {
  const { timezone } = useTimezoneContext();

  const timeTitle = useMemo(() => {
    const date = dayjs(news.published_at).tz(timezone.utc);
    if (date.isAfter(dayjs().subtract(7, "day"))) {
      return date.fromNow();
    } else {
      return date.format(DateTimeFormat);
    }
  }, [news.published_at, timezone.utc]);

  const timeDom = useMemo(
    () => (
      <Typography.Text type="secondary" className="whitespace-nowrap" title={news.published_at}>
        {timeTitle}
      </Typography.Text>
    ),
    [news.published_at, timeTitle],
  );

  const sourceTitle = useMemo(
    () => news.creator ? ellipsisString(news.creator || "", 15) : "Klarda Partner",
    [news.is_klarda, news.creator],
  );
  return (
    <div className="flex justify-between items-end w-full gap-2 flex-wrap">
      <div className="flex-grow">
        <div className="flex items-center gap-2 flex-wrap">
          <Avatar
            size={16}
            src={<img src={news.source_logo || ethLogo} alt={news.creator || "source"} className="w-4 h-4" />}
            className="border-gray-200 border-[1px]"
          />
          <Typography.Text className="whitespace-nowrap" title={news.creator}>
            {sourceTitle}
          </Typography.Text>
          <div className="flex-shrink">{timeDom}</div>
        </div>
      </div>
    </div>
  );
}
