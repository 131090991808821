import React from "react";
import { Button, Space, Typography } from "antd/lib";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useAuth } from "@/context/AuthContext";

const NotSupportedPopover = () => {
  const { openLoginModal } = useAuth();

  return (
    <Space className={"max-w-[250px]"} direction={"vertical"}>
      <div className="flex gap-2 items-start">
        <ExclamationCircleFilled style={{ fontSize: 24, color: "#faad14" }} />
        <Space direction={"vertical"} size={2}>
          <Typography.Title level={5} className={"mb-0"}>
            Premium Feature
          </Typography.Title>
          <Typography.Text>
            This feature is not supported yet. Please check our premium plans for more details.
          </Typography.Text>
        </Space>
      </div>
      <div className={"text-center mt-2"}>
        <Button onClick={openLoginModal} type={"primary"}>
          {"Upgrade"}
        </Button>
      </div>
    </Space>
  );
};

export default NotSupportedPopover;
